.block-line {
  max-width: 400vw;
  width: 1000px;
}

.block-grid {
  display: flex;
  flex-wrap: wrap;
}

.inactive {
  pointer-events: none;
}

.block {
  max-width: 40vw;
  width: 140px;
  transition: transform 0.3s;
  margin: 2px;
}

.block-icon {
  max-width: 40vw;
  width: 140px;
  transition: transform 0.3s;
}

.block-icon.activated {
  transform: translateY(10px) scale(0.8);
}

.block:active {
  transform: translateY(10px) scale(0.8);
}

.tile-red {
  background-color: #f25022;
  box-shadow: 0 0 0 1px #f25022 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 8px 0 0 #c93a12, 0 8px 0 1px rgba(0, 0, 0, 0.4),
    0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}
/*
.tile-red:hover,
.tile-red:focus {
  background-color: #ff451a;
  box-shadow: 0 0 0 1px #ff3000 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 10px 0 0 #e62b00, 0 10px 0 1px rgba(0, 0, 0, 0.4),
    0 10px 8px 1px rgba(0, 0, 0, 0.6);
}

.tile-red:active,
.tile-red.activated {
  box-shadow: 0 0 0 1px #ff5c36 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #ff5c36;
  transform: translateY(10px);
  transform: scale(0.8);
}
*/
.tile-green {
  background-color: #7fba00;
  box-shadow: 0 0 0 1px #7fba00 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 8px 0 0 #638f05, 0 8px 0 1px rgba(0, 0, 0, 0.4),
    0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}
/*
.tile-green:hover,
.tile-green:focus {
  background-color: #8cc218;
  box-shadow: 0 0 0 1px #8cc218 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 10px 0 0 #74a802, 0 10px 0 1px rgba(0, 0, 0, 0.4),
    0 10px 8px 1px rgba(0, 0, 0, 0.6);
}

.tile-green:active,
.tile-green.activated {
  box-shadow: 0 0 0 1px #a0ea00 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #a0ea00;
  transform: translateY(10px);
  transform: scale(0.8);
}
*/
.tile-yellow {
  background-color: #ffb900;
  box-shadow: 0 0 0 1px #ffb900 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 8px 0 0 #c28e07, 0 8px 0 1px rgba(0, 0, 0, 0.4),
    0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}
/*
.tile-yellow:hover,
.tile-yellow:focus {
  background-color: #ffb900;
  box-shadow: 0 0 0 1px #ffb900 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 10px 0 0 #c28e07, 0 10px 0 1px rgba(0, 0, 0, 0.4),
    0 10px 8px 1px rgba(0, 0, 0, 0.6);
}

.tile-yellow:active,
.tile-yellow.activated {
  box-shadow: 0 0 0 1px #ffca3d inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #ffca3d;
  transform: translateY(10px);
  transform: scale(0.8);
}
*/
.tile-blue {
  background-color: #00a4ef;
  box-shadow: 0 0 0 1px #00a4ef inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 8px 0 0 #0883bc, 0 8px 0 1px rgba(0, 0, 0, 0.4),
    0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}
/*
.tile-blue:hover,
.tile-blue:focus {
  background-color: #139ddd;
  box-shadow: 0 0 0 1px #139ddd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 10px 0 0 #0c7cb0, 0 10px 0 1px rgba(0, 0, 0, 0.4),
    0 10px 8px 1px rgba(0, 0, 0, 0.6);
}

.tile-blue:active,
.tile-blue.activated {
  box-shadow: 0 0 0 1px #2bbcff inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #2bbcff;
  transform: translateY(10px);
  transform: scale(0.8);
}
*/
.big-block {
  max-width: 40vw;
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.big-block-wrapper {
  width: 700px;
  height: 200px;
  justify-content: center;
  align-items: center;
}

.big-block-icon {
  width: 400px;
  height: 200px;
  margin: 0 auto;
}
