.main {
  min-height: 100vh !important;
}

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .main {
    min-height: -webkit-fill-available !important;
  }
}
}


html {
  min-height: -webkit-fill-available;
}

.App {
  text-align: center;
}

.App-logo {
  height: 10vh;
  pointer-events: none;
}

.App-logo-header {
  max-height: 15vw;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 10vh;
}

.App-link {
  color: #61dafb;
  text-decoration: none;
}

.App-footer {
  background: #d9f0f0;
}

.Social-logo {
  height: 32px;
  width: 32px;
  max-height: 8vw;
  max-width: 8vw;
  min-height: 20px;
  min-width: 20px;
  pointer-events: none;
  fill: #7D7D7D;
}

.Social-logo-inline {
  height: 25px;
  width: 25px;
  pointer-events: none;
}

.Arrow-inline {
  height: 16px;
  width: 16px;
  pointer-events: none;
}

.header-light {
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 35px;
}

.header-bold {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 35px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

.header-semi-bold {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  letter-spacing: -0.02em;
}

.header-bold-center {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 35px;
  letter-spacing: -0.02em;
  text-align: center;
}

.brand-header {
  font-style: normal;
  font-weight: 250;
  font-size: 20px;
  line-height: 24px;
}

.text-link {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 35px;
  text-decoration: none;
}

.btn-main {
  background-color: #009B9B;
  border-color: #009B9B;
  border-radius: 10px;
  color: #FFFFFF;
}

.btn-scd {
  background-color: #FFFFFF;
  border-color: #009B9B;
  border-radius: 10px;
  color: #000000;
}

.btn-for-startOver-and-hint {
  display:block;
  margin: 20px;
  margin-left: 10px;
}

.Error-img {
  max-width: 70vw;
}

.Question-img {
  max-width: 60vw;
  width: 200px;
  pointer-events: none;
}

.Question-img-sm {
  max-width: 40vw;
  width: 150px;
  pointer-events: none;
}

.no-wrap {
  white-space: nowrap;
}

.btn-block {
  width: 100%; 
  margin-top: 10px; 
  
}